import React from "react"
import SEO from "../components/seo"
import { Header } from "../components/Header"
import { Container } from "../components/Container"
import { ContactInfo } from "../components/ContactInfo"
import { Footer } from "../components/Footer"
import { Logo } from "../components/Logo"

const Contact = () => {
  return (
    <section className="min-h-screen flex flex-col justify-between">
      <SEO title="Contact Elizabeth Vincent M" />
      <Header pageHeader="Contact Me" />
      <section className="my-auto">
        <Container>
          <div className="flex flex-col items-center">
            <Logo className="w-1/3 mb-12" />
            <p className="text-lg font-serif">Let's work together!</p>
            <ContactInfo />
          </div>
        </Container>
      </section>

      <Footer />
    </section>
  )
}

export default Contact
